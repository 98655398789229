/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import styled, { css } from 'styled-components';

import { Subsite } from '../../../types';

import {
	StyledLinkNav__expandable,
	StyledLinkNav__toggle,
} from '../../atoms/LinkNav/index.styled';
import { gap } from '../../../styles/settings/spacing';
import themes from '../../../styles/settings/themes';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';
import { StyledMegaMenu } from '../MegaMenu/index.styled';

type HeadersProps = {
	toggleName?: string
	selectedNavToggleName?: string
	topLevelNav?: boolean
	selected?: boolean
};

export const StyledHeader__Toggle = styled.nav`
	position: fixed;
	top: 0;
	width: 100%;
	height: auto;
	background-color: var(--white);
	display: flex;
	z-index: 100;
	justify-content: center;

	a {
		cursor: pointer;
	}

	${above(
		breakpoints[600],
		() => css`
			height: auto;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			height: auto;
		`
	)}
`;

export const StyledHeader = styled.div<HeadersProps>`
	position: fixed;
	top: 0;
	left: 0;
	display: ${props =>
		props.selectedNavToggleName === props.toggleName ? 'flex' : 'none'};
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: '56px';
	z-index: 100;
	background-color: ${({ selectedNavToggleName }) => {
		if (selectedNavToggleName === Subsite.BruntwoodGroup) {
			return themes.sky.headerBackground;
		}
		if (selectedNavToggleName === Subsite.BruntwoodWorks) {
			return themes.sky.headerBackground;
		}
		if (selectedNavToggleName === Subsite.BruntwoodSciTech) {
			return themes.sky.headerBackground;
		}
	}};

	${above(
		breakpoints[600],
		() => css`
			height: 64px;
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			height: 72px;
		`
	)}
`;

export const Header__list = styled.ul<HeadersProps>`
	display: none;

	${props => {
		if (props.topLevelNav) {
			return css`
				height: 100%;
				width: auto;
				display: flex;
				list-style: none;
				flex-flow: row;
				justify-content: space-between;
				margin-left: auto;
				padding: ${gap[0]};

				${above(
					1140,
					() => css`
						&:hover > ${Header__list__item}, &:focus-within > ${Header__list__item} {
							background-color: var(--white);
						}

						&:hover ${StyledLinkNav__toggle}, &:focus-within ${StyledLinkNav__toggle} {
							color: var(--black);
						}

						&:hover ${StyledHeader}, &:focus-within ${StyledHeader} {
							display: none;
						}
					`
				)}
			`;
		}
	}}

	@media (min-width: 1140px) {
		height: 100%;
		width: auto;
		display: flex;
		list-style: none;
		flex-flow: row;
		justify-content: space-between;
		margin-left: auto;
		padding: ${gap[0]};
	}
`;

export const Header__wrap = styled(Container)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row wrap;
	text-align: center;
	align-items: center;
	justify-content: space-between;
`;

export const Header__list__item = styled.li<HeadersProps>`
	display: flex;
	justify-content: center;
	align-items: center;

	${props => {
		if (props.topLevelNav) {
			return css<HeadersProps>`
				background-color: ${props => {
					if (props.selected) {
						if (props.toggleName === Subsite.BruntwoodGroup) {
							return themes.sky.headerBackground;
						}
						if (props.toggleName === Subsite.BruntwoodWorks) {
							return themes.sky.headerBackground;
						}
						if (props.toggleName === Subsite.BruntwoodSciTech) {
							return themes.sky.headerBackground;
						}
					} else {
						return 'var(--white)';
					}
				}};
			`;
		}
	}}

	${above(
		1140,
		() => css<HeadersProps>`
			&:hover,
			&:focus-within {
				${props => {
					if (!props.topLevelNav) {
						return css`
							background-color: var(--white);
							& > ${StyledLinkNav__expandable} {
								color: var(--black);
							}
							${StyledMegaMenu} {
								display: flex;
							}
						`;
					}

					if (props.topLevelNav) {
						return css<HeadersProps>`
							background-color: ${props => {
								if (props.toggleName === Subsite.BruntwoodGroup) {
									return `${themes.sky.headerBackground}!important`;
								}
								if (props.toggleName === Subsite.BruntwoodWorks) {
									return `${themes.sky.headerBackground}!important`;
								}
								if (props.toggleName === Subsite.BruntwoodSciTech) {
									return `${themes.sky.headerBackground}!important`;
								}
							}};

							& > ${StyledLinkNav__toggle} {
								color: ${props => {
									if (props.toggleName === Subsite.BruntwoodWorks) {
										return 'var(--black)!important';
									}
									return 'var(--white)!important';
								}};
							}

							${StyledHeader} {
								display: flex;
								background-color: ${props => {
									if (props.toggleName === Subsite.BruntwoodGroup) {
										return themes.sky.headerBackground;
									}
									if (props.toggleName === Subsite.BruntwoodWorks) {
										return themes.sky.headerBackground;
									}
									if (props.toggleName === Subsite.BruntwoodSciTech) {
										return themes.sky.headerBackground;
									}
								}};
							}
						`;
					}
				}}
			}
		`
	)}


	a {
		line-height: 1.3;
		padding-top: 3px;
		padding-bottom: 3px;
	}
`;


export const Header__mobileToggle = styled.button`
	background: none;
	border: none;
	padding: ${gap[8]};
	margin: ${gap[0]};
	height: 48px;
	width: 48px;
	box-shadow: none;
	margin-right: -${gap[8]};
	cursor: pointer;

	&:focus {
		outline: 2px solid var(--primaryAccent);
	}

	&:hover {
		outline: 2px solid var(--primaryAccent);
	}

	@media (min-width: 1140px) {
		display: none;
		visibility: none;
	}
`;
